import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

export default class ZendeskHelper extends Helper {
  @service zendeskLocalization;

  compute(args) {
    let [articleId] = args;

    if (!articleId) {
      return this.zendeskLocalization.getLocalizedHelpCenter;
    } else {
      return this.zendeskLocalization.getLocalizedArticle(articleId);
    }
  }
}
