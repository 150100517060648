import Helper from '@ember/component/helper';
import { service } from '@ember/service';

import { displayCardName } from 'qonto/utils/cards/display-card-name';

export default class DisplayCardName extends Helper {
  @service intl;

  compute([cardLevel]) {
    return displayCardName(cardLevel, this.intl);
  }
}
